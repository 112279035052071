var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "select-users-wrap", staticStyle: { height: "100%" } },
    [
      _c("div", { staticClass: "flex-row", staticStyle: { height: "100%" } }, [
        _vm.loginKey === "loginUser" && !_vm.orgId
          ? _c(
              "div",
              { staticClass: "part-box" },
              [
                _c(
                  "el-card",
                  {
                    staticClass: "body-small custom-card",
                    staticStyle: { height: "100%" },
                    attrs: { shadow: "never" },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "clearfix",
                        attrs: { slot: "header" },
                        slot: "header",
                      },
                      [
                        _c(
                          "el-button",
                          {
                            staticStyle: { padding: "0 11px" },
                            attrs: { type: "text" },
                            on: { click: _vm.getAllUsers },
                          },
                          [_vm._v("全部用户>>")]
                        ),
                      ],
                      1
                    ),
                    _c("el-tree", {
                      attrs: {
                        data: _vm.orgsTree,
                        "expand-on-click-node": false,
                        "default-expand-all": "",
                      },
                      on: { "node-click": _vm.handleNodeClick },
                    }),
                  ],
                  1
                ),
              ],
              1
            )
          : _vm._e(),
        _c(
          "div",
          { staticClass: "flex-item table-box" },
          [
            _c(
              "div",
              {
                staticClass: "flex-row",
                staticStyle: { "align-items": "center" },
                on: {
                  keyup: function ($event) {
                    if (!$event.type.indexOf("key") && $event.keyCode !== 13) {
                      return null
                    }
                    return _vm.handleSearch.apply(null, arguments)
                  },
                },
              },
              [
                _c(
                  "el-input",
                  {
                    staticStyle: { margin: "10px", width: "200px" },
                    attrs: { size: "mini", placeholder: "请输入内容" },
                    model: {
                      value: _vm.searchKey,
                      callback: function ($$v) {
                        _vm.searchKey = $$v
                      },
                      expression: "searchKey",
                    },
                  },
                  [
                    _c("i", {
                      staticClass: "el-input__icon el-icon-search",
                      attrs: { slot: "prefix" },
                      slot: "prefix",
                    }),
                  ]
                ),
                _c(
                  "el-button",
                  {
                    attrs: {
                      type: "primary",
                      icon: "el-icon-search",
                      size: "mini",
                    },
                    on: { click: _vm.handleSearch },
                  },
                  [_vm._v("查询")]
                ),
                _vm.names
                  ? _c(
                      "div",
                      {
                        staticClass: "flex-item ellipsis",
                        staticStyle: {
                          "text-align": "right",
                          padding: "5px 10px",
                        },
                        attrs: { title: _vm.names },
                      },
                      [_vm._v("当前选中：" + _vm._s(_vm.names))]
                    )
                  : _vm._e(),
              ],
              1
            ),
            _vm.loginKey === "loginUser"
              ? _c(
                  "el-table",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.tableData.loading,
                        expression: "tableData.loading",
                      },
                    ],
                    ref: "multipleTable",
                    staticStyle: {
                      width: "100%",
                      "border-top": "1px solid #e4e4e4",
                    },
                    attrs: {
                      height: "calc(100% - 52px - 36px)",
                      data: _vm.tableData.datas,
                      "tooltip-effect": "dark",
                    },
                    on: {
                      "row-click": _vm.rowClick,
                      select: _vm.handleSelectionUser,
                      "select-all": _vm.handleSelectionUser,
                    },
                  },
                  [
                    _c("el-table-column", {
                      attrs: {
                        align: "center",
                        type: "selection",
                        width: "55",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        align: "center",
                        "min-width": "80px",
                        label: "账号",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("span", { staticClass: "link-type" }, [
                                  _vm._v(_vm._s(scope.row.account)),
                                ]),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        2040981588
                      ),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        align: "center",
                        "min-width": "80px",
                        label: "姓名",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("span", [_vm._v(_vm._s(scope.row.name))]),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        2020036417
                      ),
                    }),
                    _c("el-table-column", {
                      attrs: { align: "center", label: "所属部门" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("span", [
                                  _vm._v(_vm._s(scope.row.organizations)),
                                ]),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        3882350670
                      ),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        align: "center",
                        "class-name": "status-col",
                        label: "状态",
                        width: "100",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c(
                                  "span",
                                  {
                                    class: _vm._f("userStatusFilter")(
                                      scope.row.status
                                    ),
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.statusOptions.find(function (u) {
                                          return u.key == scope.row.status
                                        }).display_name
                                      )
                                    ),
                                  ]
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        2702470429
                      ),
                    }),
                  ],
                  1
                )
              : _c(
                  "el-table",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.tableData.loading,
                        expression: "tableData.loading",
                      },
                    ],
                    ref: "multipleTable",
                    staticStyle: { width: "100%" },
                    attrs: {
                      height: "calc(100% - 52px - 36px)",
                      data: _vm.tableData.datas,
                      "tooltip-effect": "dark",
                      border: "",
                    },
                    on: {
                      "row-click": _vm.rowClick,
                      select: _vm.handleSelectionUser,
                      "select-all": _vm.handleSelectionUser,
                    },
                  },
                  [
                    _c("el-table-column", {
                      attrs: {
                        align: "center",
                        type: "selection",
                        width: "55",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        align: "center",
                        "min-width": "50px",
                        label: "角色名称",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("span", [_vm._v(_vm._s(scope.row.name))]),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        align: "center",
                        "class-name": "status-col",
                        label: "状态",
                        width: "100",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "span",
                                {
                                  class: _vm._f("userStatusFilter")(
                                    scope.row.status
                                  ),
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.statusOptions.find(function (u) {
                                        return u.key == scope.row.status
                                      }).display_name
                                    )
                                  ),
                                ]
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                  ],
                  1
                ),
            _c("el-pagination", {
              staticStyle: { "margin-top": "3px", "text-align": "right" },
              attrs: {
                background: false,
                layout: "prev, pager, next",
                total: _vm.tableData.total,
                "page-size": _vm.tableData.listQuery.limit,
              },
              on: { "current-change": _vm.handlePageSearch },
            }),
          ],
          1
        ),
      ]),
      !_vm.hiddenFooter
        ? _c(
            "div",
            { staticStyle: { "text-align": "right", "margin-top": "10px" } },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "cancel" },
                  on: { click: _vm.handleClose },
                },
                [_vm._v("取消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.handleSaveUsers },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }